import { Spinner, Flex, Input } from '@urbaninfrastructure/react-ui-kit';
import styled from 'styled-components';
import { motion, useReducedMotion } from 'framer-motion';

const StyledDigitInput = styled(Input)`
  font-style: normal;
`;

export const DigitInput = ({
  value,
  fields,
  onChange,
  loading,
  center
}: {
  value: string;
  fields: number;
  loading: boolean;
  center?: boolean;
  onChange: (value: string) => void;
}) => {
  const reduceMotion = useReducedMotion();
  const startingX = reduceMotion ? 0 : 50;
  return (
    <Flex
      position="relative"
      alignItems={center ? 'center' : undefined}
      justifyContent={center ? 'center' : undefined}
    >
      {loading && (
        <motion.div
          initial={{ x: startingX, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: startingX, opacity: 0 }}
          style={{ position: 'absolute' }}
        >
          <Spinner size={24} color="primary" />
        </motion.div>
      )}
      <StyledDigitInput
        id="digit-input"
        name="digit-input"
        type="text"
        alternative
        autoFocus
        maxLength={fields}
        disabled={loading}
        width={`${fields * 1.2}em`}
        autoComplete="one-time-code"
        inputMode="numeric"
        pattern="[0-9]*"
        fontSize={{ _: 3, md: 4 }}
        textAlign="center"
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        style={{ letterSpacing: '.25em' }}
      />
    </Flex>
  );
};
