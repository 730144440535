import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import cookies from '../lib/cookies';
import redirect from '../lib/redirect';
import { JWT_COOKIE_NAME } from '../lib/constants';
import { LogIn } from '../modules/LogIn';
import Layout from '../modules/Layout';
import { Box, Heading } from '../components';
import { replaceRoute } from '../components/Link';
import { CustomNextPage } from '../lib/types';
import { getQueryString } from '../lib/utils';
import { globalMessages } from '../lib/messages';
import { bootstrap } from '../lib/bootstrap';

const messages = defineMessages({
  heading: {
    id: 'LogInPage.heading',
    defaultMessage: 'Log in with one-time code'
  }
});

const LogInPage: CustomNextPage<{}> = ({ url }) => {
  const intl = useIntl();
  const prefix = getQueryString(url.query.prefix) || '';
  const nationalPhoneNumber =
    getQueryString(url.query.nationalPhoneNumber) || '';
  return (
    <Layout
      title={intl.formatMessage(globalMessages.logIn)}
      centerContent
      loading={false}
      url={url}
      render={() => (
        <Box data-testid="LogInPage" width={[1, 1, 2 / 3, 2 / 3, 1 / 2, 1 / 3]}>
          <LogIn
            initialValues={{ prefix, nationalPhoneNumber }}
            onSuccess={() => {
              // Redirect home
              replaceRoute({
                route: getQueryString(url.query.returnRoute) || 'profile',
                intl
              });
            }}
          >
            {content => {
              return (
                <>
                  <Heading mb={5} textAlign="center">
                    <FormattedMessage {...messages.heading} />
                  </Heading>
                  {content}
                </>
              );
            }}
          </LogIn>
        </Box>
      )}
    />
  );
};

LogInPage.getInitialProps = ctx => {
  // if already logged in, redirect home
  if (cookies.parse(ctx.req)[JWT_COOKIE_NAME]) {
    redirect(ctx, '/');
  }
  return {};
};

export default bootstrap(LogInPage);
