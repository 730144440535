import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, FormikErrors, useFormikContext } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  AnchorButton,
  Flex,
  Text,
  Box,
  Alert,
  Spinner
} from '../../../components';
import { formatPhoneNumber } from '../../../lib/util';
import { queriesToRefetchAfterCurrentUserMutation } from '../../../lib/core';
import { globalMessages } from '../../../lib/messages';
import {
  LOGIN_MUTATION,
  UPDATE_PHONE_NUMBER_MUTATION,
  CREATE_VERIFICATION,
  VERIFY_PHONE_CODE_AND_AUTHENTICATE
} from '../graphql';
import { CreateVerificationErrorAlert } from '../CreateVerificationErrorAlert';
import {
  loginMutation,
  loginMutationVariables,
  VerificationMode,
  createPhoneVerificationMutation,
  createPhoneVerificationMutationVariables,
  twilioUpdatePhoneNumberMutation,
  twilioUpdatePhoneNumberMutationVariables,
  twilioUpdatePhoneNumberMutation_updatePhoneNumber,
  loginMutation_verifyCodeAndLogIn,
  verifyPhoneCodeAndAuthenticateMutation,
  verifyPhoneCodeAndAuthenticateMutationVariables,
  verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate,
  CurrentUser,
  settings as settingsQuery
} from '../../../core-types';
import { useStoredVerification } from '../useStoredVerification';
import { usePrevious } from '../../../lib/hooks';
import { DigitInput } from '../DigitInput';
import { useStaticDataQuery } from '../../../lib/core/staticDataQuery';
import { useOnCodeVerified } from '../../../lib/with-auth';
import { SETTINGS_QUERY } from '../../../lib/core/SettingsQuery';

type Props = {
  mode: VerificationMode;
  twilioVerificationSid: string;
  nationalPhoneNumber: string;
  prefix: string;
  onLogIn: ({ jwt, user }: { jwt: string; user: CurrentUser }) => void;
  onPhoneNumberUpdate?: (
    obj: twilioUpdatePhoneNumberMutation_updatePhoneNumber
  ) => void;
  center?: boolean;
};

function ResendCode({ prefix, nationalPhoneNumber, mode, onCodeResent }) {
  const [createVerification, { loading, error, data }] = useMutation<
    createPhoneVerificationMutation,
    createPhoneVerificationMutationVariables
  >(CREATE_VERIFICATION, {
    onCompleted: data => {
      if (data.createPhoneVerification.__typename === 'PhoneVerification') {
        onCodeResent();
      }
    }
  });
  const createPhoneVerification = data && data.createPhoneVerification;

  return (
    <>
      {error && (
        <Alert variant="error" mb="sm">
          <FormattedMessage {...globalMessages.genericError} />
        </Alert>
      )}
      {createPhoneVerification && (
        <CreateVerificationErrorAlert
          createPhoneVerification={createPhoneVerification}
        />
      )}
      <FormattedMessage
        id="modules.VerifyPhoneNumber.ResendCode.requestNewCode"
        defaultMessage="Didn't receive the code? <button>Send new</button>"
        values={{
          // eslint-disable-next-line react/display-name
          button: str => (
            <AnchorButton
              disabled={loading}
              onClick={() => {
                createVerification({
                  variables: {
                    input: {
                      phoneNumber: prefix + nationalPhoneNumber,
                      mode
                    }
                  }
                });
              }}
            >
              {str}
            </AnchorButton>
          )
        }}
      />
      {loading && (
        <Box as="span" ml={2}>
          <Spinner size={16} color="primary" />
        </Box>
      )}
    </>
  );
}

type Values = {
  code: string;
};

function AutoSubmitter({ loading }: { loading: boolean }) {
  const { submitForm, values } = useFormikContext<Values>();
  const { code } = values;
  const prevCode = usePrevious<string>(code);
  useEffect(() => {
    if (!loading && code && code !== prevCode) {
      submitForm();
    }
  }, [submitForm, loading, code, prevCode]);
  return null;
}

export function EnterCode({
  onLogIn,
  onPhoneNumberUpdate,
  twilioVerificationSid,
  nationalPhoneNumber,
  prefix,
  mode,
  center
}: Props) {
  const onCodeVerified = useOnCodeVerified();
  const initialValues: Values = {
    code: ''
  };
  const [, , removeStoredVerification] = useStoredVerification();
  const [newCodeSent, setNewCodeSent] = useState(false);
  const { data: settingsData } = useQuery<settingsQuery, {}>(SETTINGS_QUERY);
  const [logIn, logInResult] = useMutation<
    loginMutation,
    loginMutationVariables
  >(LOGIN_MUTATION, {
    onCompleted: data => {
      if (data.verifyCodeAndLogIn.__typename === 'VerifyCodeAndLogInSuccess') {
        onCodeVerified(data.verifyCodeAndLogIn);
        onLogIn(data.verifyCodeAndLogIn);
      }
    }
  });

  const [
    verifyPhoneCodeAndSignUp,
    verifyPhoneCodeAndSignUpResult
  ] = useMutation<
    verifyPhoneCodeAndAuthenticateMutation,
    verifyPhoneCodeAndAuthenticateMutationVariables
  >(VERIFY_PHONE_CODE_AND_AUTHENTICATE, {
    // refetchQueries: [{ query: INTERCOM_SETTINGS_QUERY }],
    onCompleted: data => {
      if (
        data.verifyPhoneCodeAndAuthenticate.__typename ===
        'SuccessfulAuthentication'
      ) {
        onCodeVerified(data.verifyPhoneCodeAndAuthenticate);
        onLogIn(data.verifyPhoneCodeAndAuthenticate);
      }
    }
  });

  const [updatePhoneNumber, updatePhoneNumberResult] = useMutation<
    twilioUpdatePhoneNumberMutation,
    twilioUpdatePhoneNumberMutationVariables
  >(UPDATE_PHONE_NUMBER_MUTATION, {
    refetchQueries: () => queriesToRefetchAfterCurrentUserMutation,
    onCompleted: data => {
      if (
        onPhoneNumberUpdate &&
        data.updatePhoneNumber.__typename ===
          'VerifyCodeAndUpdatePhoneNumberSuccess'
      ) {
        onPhoneNumberUpdate(data.updatePhoneNumber);
        removeStoredVerification();
      }
    }
  });

  const consents = JSON.parse(localStorage.getItem('consents') || '{}');

  const loading =
    logInResult.loading ||
    verifyPhoneCodeAndSignUpResult.loading ||
    updatePhoneNumberResult.loading;
  const error =
    logInResult.error ||
    verifyPhoneCodeAndSignUpResult.error ||
    updatePhoneNumberResult.error;
  const { data: staticDataQueryData } = useStaticDataQuery();
  const codeLength =
    (staticDataQueryData && staticDataQueryData.phoneVerificationCodeLength) ||
    4;
  return (
    <Formik<Values>
      initialValues={initialValues}
      validate={values => {
        let errors: FormikErrors<Values> = {};
        if (!values.code) {
          errors.code = 'Required';
        }

        if (values.code.length !== codeLength) {
          errors.code = 'Invalid length';
        }

        return errors;
      }}
      onSubmit={values => {
        const variables = {
          input: {
            twilioVerificationSid,
            code: values.code
          }
        };
        switch (mode) {
          case VerificationMode.login: {
            logIn({
              variables
            });
            break;
          }
          case VerificationMode.update: {
            updatePhoneNumber({ variables });
            break;
          }
          case VerificationMode.signup: {
            verifyPhoneCodeAndSignUp({
              variables: {
                input: {
                  twilioVerificationSid,
                  code: values.code,
                  legalConsents: settingsData?.system?.legalConsents?.map(
                    consent => {
                      return {
                        type: consent.type,
                        enforcement: consent.enforcement,
                        accepted: consents[consent.type]
                      };
                    }
                  )
                }
              }
            });
            break;
          }
        }
      }}
    >
      {({ setFieldValue, values }) => {
        let mutationResponse:
          | loginMutation_verifyCodeAndLogIn
          | verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate
          | null = null;

        if (logInResult.data) {
          mutationResponse = logInResult.data.verifyCodeAndLogIn;
        } else if (verifyPhoneCodeAndSignUpResult.data) {
          mutationResponse =
            verifyPhoneCodeAndSignUpResult.data.verifyPhoneCodeAndAuthenticate;
        }

        return (
          <Box textAlign={center ? 'center' : 'left'}>
            <Form id="enter-code-form">
              <AutoSubmitter loading={loading} />
              {mutationResponse &&
                mutationResponse.__typename === 'PhoneVerificationError' && (
                  <Alert variant="error" mb="sm">
                    {mutationResponse.message}
                  </Alert>
                )}
              {error && (
                <Alert variant="error" mb="sm">
                  <FormattedMessage {...globalMessages.genericError} />
                </Alert>
              )}
              <DigitInput
                fields={codeLength}
                onChange={value => setFieldValue('code', value)}
                value={values.code}
                loading={loading}
                center={center}
              />
              <Flex
                width={1}
                justifyContent="center"
                data-testid="EnterCodeForm__SentToAlert"
                flexDirection="column"
              >
                <Box width={1} mt="sm" mb="sm">
                  {!newCodeSent && (
                    <label htmlFor="digit-input">
                      <FormattedMessage
                        id="modules.VerifyPhoneNumber.EnterCode.label"
                        defaultMessage="Enter the {codeLength} digit code sent to {phoneNumber}"
                        values={{
                          codeLength,
                          phoneNumber: (
                            <Text style={{ whiteSpace: 'nowrap' }}>
                              {formatPhoneNumber(prefix, nationalPhoneNumber)}
                            </Text>
                          )
                        }}
                      />
                    </label>
                  )}
                  {newCodeSent && (
                    <FormattedMessage
                      id="modules.VerifyPhoneNumber.EnterCode.newCodeSent"
                      defaultMessage="New code sent to {phoneNumber}"
                      values={{
                        phoneNumber: (
                          <Text style={{ whiteSpace: 'nowrap' }}>
                            {formatPhoneNumber(prefix, nationalPhoneNumber)}
                          </Text>
                        )
                      }}
                    />
                  )}
                </Box>
                <Box>
                  <ResendCode
                    prefix={prefix}
                    nationalPhoneNumber={nationalPhoneNumber}
                    mode={mode}
                    onCodeResent={() => {
                      setNewCodeSent(true);
                    }}
                  />
                </Box>
              </Flex>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}
