import { ReactElement } from 'react';
import { VerifyPhoneNumber } from '../VerifyPhoneNumber';
import { VerificationMode } from '../../core-types';

export function LogIn({
  initialValues,
  onSuccess,
  children
}: {
  initialValues: { prefix: string; nationalPhoneNumber: string };
  children: (content: ReactElement, codeSent: boolean) => ReactElement;
  onSuccess: () => void;
}) {
  return children(
    <VerifyPhoneNumber
      mode={VerificationMode.login}
      center
      initialValues={initialValues}
      onLogIn={() => {
        onSuccess();
      }}
    />,
    false
  );
}
