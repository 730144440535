import { useEffect, useState } from 'react';
import * as React from 'react';
import { CreateVerification } from './CreateVerification';
import { EnterCode } from './EnterCode';
import {
  VerificationMode,
  twilioUpdatePhoneNumberMutation_updatePhoneNumber
} from '../../core-types';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex } from '../../components';
import { CheckmarkAlt } from '@urbaninfrastructure/react-icons';
import { useDimensions, DimensionObject } from '../../lib/use-dimensions';
import { CreateVerificationInitialValues } from './types';

export function VerifyPhoneNumber({
  onLogIn,
  onPhoneNumberUpdate,
  initialValues = {
    prefix: '',
    nationalPhoneNumber: '',
    accepted: false
  },
  mode: initialMode,
  center,
  additionalSendCodeButton,
  label
}: {
  initialValues?: CreateVerificationInitialValues & {
    twilioVerificationSid?: string;
  };
  // used if mode === login
  onLogIn?: () => void;
  // used if mode === update
  onPhoneNumberUpdate?: (
    arg: twilioUpdatePhoneNumberMutation_updatePhoneNumber
  ) => void;
  mode: VerificationMode;
  center?: boolean;
  additionalSendCodeButton?: React.ReactNode;
  label?: React.ReactNode;
}) {
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [mode, setMode] = useState(initialMode);
  const [positionAbsolute, setPositionAbsolute] = useState(false);
  const [state, setState] = useState({
    twilioVerificationSid: initialValues.twilioVerificationSid,
    nationalPhoneNumber: initialValues.nationalPhoneNumber,
    prefix: initialValues.prefix
  });
  const firstDimensions = React.useRef<DimensionObject | undefined>();
  const [ref, dimensions] = useDimensions({
    liveMeasure: false
  });

  useEffect(() => {
    if (!dimensions.width || positionAbsolute) {
      return;
    }
    setPositionAbsolute(true);
  }, [dimensions.width]);

  if (!firstDimensions.current && dimensions.width && dimensions.height) {
    firstDimensions.current = dimensions;
  }

  const width = firstDimensions.current && firstDimensions.current.width;
  const height = firstDimensions.current && firstDimensions.current.height;

  let key;
  let component;

  if (!loggedIn && !state.twilioVerificationSid) {
    key = 'create-verification';
    component = (
      <CreateVerification
        initialValues={{
          prefix: initialValues.prefix,
          nationalPhoneNumber: initialValues.nationalPhoneNumber,
          accepted: initialValues.accepted
        }}
        mode={mode}
        setMode={setMode}
        center={center}
        label={label}
        onCodeSent={values => {
          setState(values);
        }}
        additionalButton={additionalSendCodeButton}
        onUseCodeClick={verification => {
          setState(verification);
        }}
      />
    );
  } else if (!loggedIn && state.twilioVerificationSid) {
    key = 'enter-code';
    component = (
      <EnterCode
        twilioVerificationSid={state.twilioVerificationSid}
        nationalPhoneNumber={state.nationalPhoneNumber}
        prefix={state.prefix}
        mode={mode}
        center={center}
        onLogIn={() => {
          setLoggedIn(true);
          onLogIn && onLogIn();
        }}
        onPhoneNumberUpdate={onPhoneNumberUpdate}
      />
    );
  } else {
    key = 'logged-in';
    component = (
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{ width, height }}
      >
        <CheckmarkAlt color="primaryIcon" size={80} />
      </Flex>
    );
  }

  return (
    <Flex
      position="relative"
      margin={center ? '0 auto' : undefined}
      justifyContent={center ? 'center' : undefined}
      alignItems={center ? 'center' : undefined}
      style={{ width, height }}
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={key}
          ref={ref}
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          style={
            positionAbsolute
              ? {
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  width: '100%'
                }
              : {}
          }
        >
          {component}
        </motion.div>
      </AnimatePresence>
    </Flex>
  );
}
